import React, { FunctionComponent } from 'react';

type Props = {
  text?: string;
};

const EmptyPage: FunctionComponent<Props> = ({ text = 'Empty page' }) => {
  return (
    <div className="sanity-error-page sanity-error-page--404">
      <div>{text}</div>
    </div>
  );
};

export default EmptyPage;
